<template>
<div >
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>

  <transition name="fade">

    <div class="card" v-if="state.initialMyList.length > 0 && !state.loaderEnabled">

      <el-container>
        <el-aside width="280px" style="overflow: hidden">

          <el-tabs class="tabsPresta pt-12" style="padding: 0 2.25rem;">
            <el-tab-pane disabled label="Synthèse et filtres" ></el-tab-pane>
          </el-tabs>

          <div class="card-header border-0 ">

            <div class="card-title">
                <el-select v-model="state.type_stat" class="mt-3 nobg w-250px" placeholder="" size="large" >
                  <el-option label="Répartition par intervenant :"  value="presta_nom_prenom" />
                  <el-option label="Répartition par statut :"  value="tis_status" />
                </el-select>
            </div>
            <SyntheseIntervenant widget-classes="mb-12" :myList="state.myList" :colors="state.colors"  :mField="state.type_stat" :key="{ll:state.myList, aa:state.type_stat, bb: state.compteur}"></SyntheseIntervenant>

            <div class="card-title">
              <h4 class="fw-bolder mb-0 mt-6">Filtrer la liste :</h4>
            </div>
            <SearchAndFilter
              :listInitial="state.initialMyList"
              :filters="tableFilters"
              @returnSearch="state.myList = $event"
              @returnLoadingDatatable="state.loadingDatatable = $event"
              widthDecalage="0"
              searchBar="0"
              classGroupSelect="align-items-center"
              classSelect="mx-0 w-250px text-primary me-3 m-2 mt-2"
            ></SearchAndFilter>

  <!--
            <div class="card-title">
              <h4 class="fw-bolder mb-3 mt-4">Période:</h4>
            </div>
            <DatePicker v-model="state.rangeFilter" class="border-0" is-range />
-->
          </div>


        </el-aside>
        <el-main>

          <el-tabs v-model="activeName" class="border-0 tabsPresta pt-6" style="padding: 0 2.25rem;" @tab-click="handleClickPane">

            <el-tab-pane label="Liste des tickets" class="p-0" name="first" style="margin: 15px">

                <div class="card-header border-0 m-0 p-0">
                  <SearchAndFilter
                    :listInitial="state.initialMyList"
                    :filters="[]"
                    @returnSearch="state.myList = $event"
                    @returnLoadingDatatable="state.loadingDatatable = $event"
                    ExcelFilename="Stillnetwork_Tickets"
                    :ExcelFormat="ExcelFormat"
                  ></SearchAndFilter>
                </div>
                <div class="card-body pt-0 p-0">
                  <div
                    v-if="state.myList.length == 0"
                    class=" alert bg-light-primary  alert-primary  d-flex  align-items-center  p-5  mb-10"
                  >
                    <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                    <inline-svg src="media/icons/duotune/general/gen048.svg" />
                            </span>
                            <div class="d-flex flex-column">
                    <h4 class="mb-1 text-dark">Aucun Contrat</h4>
                    <span>Il n'existe aucun ticket à afficher correspondant à vos critères de recherche.</span>
                  </div>
                  </div>
                  <Datatable
                    v-if="state.myList && state.myList.length > 0"
                    :table-data="state.myList"
                    :table-header="tableHeader"
                    :enable-items-per-page-dropdown="true"
                    empty-table-text="Aucun ticket à afficher."
                    :key="state.loadingDatatable"
                  >
                    <template v-slot:cell-tic_seq="{ row: data }">
                      <div class="fw-bolder text-gray-600 fs-6 m-titre w-250px" ><i class="fas fa-square fs-5 me-2" :style="`color: #${data.color}`"></i> Ticket #{{ data.tic_seq }}</div>
                      <div class="text-muted fw-bold d-block mt-2 m-titre" >{{ data.soc_nom_societe }}</div>
                      <div class="fw-bolder text-gray-900 fs-6 m-titre">{{ data.tic_c_label }}</div>
                    </template>

                    <template v-slot:cell-tis_status="{ row: data }">
                      <div style="width:310px;">
                        <!--{{ statusJson(data.cat_status).status }}-->
                        <el-select v-model="data.tis_status" class="m-2 nobg w-250px" placeholder="A définir" size="large" @change="changeStatus(data)"
                          :disabled="data.tis_status=='Réalisé'"
                        >
                          <el-option
                            v-for="item in statusJson(data.cat_status).status"
                            :key="item.label"
                            :value="item.label"
                            :label="`${item.label == 'Réalisé' ? '✅' : '⌛'} ${item.label}`"
                          >
                            <span v-if="item.next== 0">⌛ {{ item.label }}</span>
                            <span v-if="item.next==-1">🔃 {{ item.label }}</span>
                            <span v-if="item.next== 1">✅ {{ item.label }}</span>
                          </el-option>
                        </el-select>
                        <!--
                        <span
                          style="cursor: default; text-align: left; font-size: 0.875em; text-transform: uppercase;"
                          :class="styleBtnStatus(data)"
                          class="mt-2 mb-2 btn bg-white btn-sm btn-text-dark w-250px"
                        >
                          <span class="svg-icon svg-icon-1">
                            <inline-svg :src="styleImgStatus(data)" />
                          </span>
                          {{ data.tis_status }}
                        </span>

                        <el-tooltip content="Considérer comme 'Planifié'" 
                          v-if="data.tis_status=='En cours de planification' && data.tis_date_planif && data.tis_knum_contact_presta"
                          placement="top" effect="light" :show-after="500">
                            <a class="btn btn-sm btn-success btn-active-light-success" style="width: 56px;" @click='changeStatus(data)'>
                              <span class="svg-icon svg-icon-1 svg-icon-success">
                                <inline-svg src="media/icons/duotune/general/gen043.svg"/> </span>

                            </a>
                        </el-tooltip>
                        -->

                      </div>
                    </template>

                    <template v-slot:cell-tis_date_planif="{ row: data }">
                      <span class="text-primary fw-bolder py-1 m-2 nobg">
                        <el-date-picker v-model="data.tis_date_planif" format="DD/MM/YYYY" value-format="YYYY-MM-DD" style="width: 150px" type="date" placeholder="Planifier"  @blur="dateChangePlanif(data)" @change="dateChangePlanif(data)"
                        :disabled="!(data.tis_status=='En cours de planification' || !data.tis_date_planif)" />
                      </span>
                      <el-select v-model="data.tis_knum_contact_presta" class="m-2 nobg w-150px" placeholder="A définir" size="large" clearable  @change="changeInter(data)" 
                      :disabled="state.role_web!='Administrateur'||data.tis_status=='Réalisé'" >
                        <el-option
                          v-for="item in state.allContacts"
                          :key="item.value"
                          :label="item.con_nom_prenom_ab"
                          :value="item.con_seq"
                        />
                      </el-select>
                    </template>




                    <template v-slot:cell-total_files="{ row: data }">
                      <span v-if=" data.total_files>0">
                      {{ data.total_files }} fichier{{ data.total_files>0 ? 's' : '' }}
                      </span>
                      <span v-else class="text-danger">
                        Aucun fichier
                      </span>                  
                    </template>

                    <template v-slot:cell-tic_knum_societe="{ row: data }">
                      <!-- data.tis_status=='En cours de planification' &&  -->
                      <!--
                    <a class="btn btn-sm btn-light-success me-2 mb-2 btn-text-dark" @click='applyChange(data)'
                      v-if="((data.tis_date_planif != data.old_tis_date_planif) || (data.tis_knum_contact_presta != data.old_tis_knum_contact_presta ))"
                    >
                        <span class="svg-icon svg-icon-1 svg-icon-white">
                          <inline-svg src="media/icons/duotune/general/gen043.svg"/> </span>
                          Appliquer
                      </a>
                      -->
                    <a class="btn btn-sm btn-active-primary me-2 mb-2 btn-bg-white btn-text-dark" @click='affDetail(data.tic_seq, data.tis_seq)'>
                        <span class="svg-icon svg-icon-1 svg-icon-primary">
                          <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>
                          Afficher
                      </a>
                    </template>
                    
                  </Datatable>
                </div>

            </el-tab-pane>
            
            <el-tab-pane label="Planning d'interventions" name="second">
              <PlanningPresta widget-classes="mb-12" @affTicket="affTicket($event)" :myList="state.myList" :mField="state.type_stat" :key="{ll:state.myList, aa:state.type_stat, cc: state.compteur}"></PlanningPresta>
            </el-tab-pane>

            <el-tab-pane label="cartographie" name="third">
              <div style="height: calc(100vh - 240px);">
                <MapPresta v-if="state.affMap" widget-classes="mb-12" @affTicket="affTicket($event)" :myList="state.myList" :mField="state.type_stat" :key="{ll:state.myList, aa:state.type_stat, cc: state.compteur}"></MapPresta>
              </div>
            </el-tab-pane>

            
            
          </el-tabs>


        </el-main>
      </el-container>

    </div>

  </transition>
  <el-drawer v-model="state.drawer" :with-header="false" size="90%" modal-class="modal-transp" v-show="state.ticketSelect.tic_seq">
    <div class="card shadow-none" style="width: 100%; height: 100%;" v-if="state.drawer">
      <div class="card-header" id="kt_activities_header" style="background: white;">
        <div class="page-title d-flex  align-items-center  flex-wrap me-3 mb-5 mb-lg-0 ">
          <button @click="state.drawer = false" class="btn btn-light me-3"><i class="fas fa-arrow-left fs-4 me-2" ></i> Retour</button>

          <template v-if="state.onUpload==0">
            <el-upload ref="uploadRef" action="#" :on-change="submitUpload" :multiple=true :show-file-list="false" :auto-upload="false">
              <button class="btn btn-primary me-3"><i class="fas fa-upload fs-4 me-2" ></i>Joindre un nouveau fichier</button>
            </el-upload>
          </template>
          <template v-else>
            <div class="btn btn-info me-3"><i class="fas fa-circle-notch fa-spin fs-4 me-2" style="text-indent: 5px;"></i>Chargement du fichier...</div>
          </template>

          <span class="text-primary fw-bolder py-1 m-2 nobg">
            <el-date-picker v-model="state.valTicket.tic_c_serv[0].tis_date_planif" format="DD/MM/YYYY" value-format="YYYY-MM-DD" style="width: 150px" type="date" placeholder="Planifier"  @blur="dateChangePlanif(state.valTicket.tic_c_serv[0])" @change="dateChangePlanif(state.valTicket.tic_c_serv[0])"
            :disabled="!(state.valTicket.tic_c_serv[0].tis_status=='En cours de planification' || !state.valTicket.tic_c_serv[0].tis_date_planif)" />
          </span>
          <el-select v-model="state.valTicket.tic_c_serv[0].tis_knum_contact_presta" class="m-2 nobg w-250px" placeholder="Intervenant à définir" size="large" clearable  @change="changeInter(state.valTicket.tic_c_serv[0])" 
          :disabled="state.role_web!='Administrateur'||state.valTicket.tic_c_serv[0].tis_status=='Réalisé'" >
            <el-option
              v-for="item in state.allContacts"
              :key="item.value"
              :label="item.con_nom_prenom_ab"
              :value="item.con_seq"
            />
          </el-select>

          <el-select v-model="state.valTicket.tic_c_serv[0].tis_status" class="m-2 nobg w-250px" placeholder="A définir" size="large" @change="changeStatus(state.valTicket.tic_c_serv[0])"
            :disabled="state.valTicket.tic_c_serv[0].tis_status=='Réalisé'"
          >
            <el-option
              v-for="item in statusJson(state.valTicket.tic_c_serv[0].cat_status).status"
              :key="item.label"
              :value="item.label"
              :label="`${item.label == 'Réalisé' ? '✅' : '⌛'} ${item.label}`"
            >
              <span v-if="item.next== 0">⌛ {{ item.label }}</span>
              <span v-if="item.next==-1">🔃 {{ item.label }}</span>
              <span v-if="item.next== 1">✅ {{ item.label }}</span>
            </el-option>
          </el-select>
          
        </div>
      </div>
      <div class="card-body position-relative" id="kt_activities_body" style="overflow: auto !important;  height: calc(100vh - 100px); background: url(media/still/OLDEbackg.jpg) no-repeat center center fixed;  background-size: cover;">
        <el-main class='pt-0' style="padding-right: 0 !important;">
          <el-container>
            <el-main class='pt-0' style='padding-left: 13px !important;'>
              <affTicket v-if="!state.loaderEnabled" :objTicket="state.valTicket" :key="state.valTicket" :modeTic='1' @reloadTicket="affDetail(state.selTicket, state.valTis);" />
            </el-main>
            <el-aside width="350px" class="d-none d-xxl-block">
              <chat :deltaHauteur="state.hauteurChat" class="m-ticket" :style="`border-radius: 5px; cursor: default; position: fixed;right: 15px;width: 358px;top:${state.topChat}px`"  dis_type_entity="ticket"  :dis_knum_entity="state.selTicket" :key="state.selTicket || state.hauteurChat" v-if="state.selTicket > 0" />
            </el-aside>
          </el-container>

        </el-main>
          
      </div>
    </div>
  </el-drawer>
  </div>
  <div class="card" v-if="state.initialMyList.length == 0 && !state.loaderEnabled">
    <el-empty description="Vous n'avez aucun ticket à traiter pour le moment"></el-empty>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import affTicket from "@/views/still/deploy/affTicket.vue";
import chat from "@/views/still/chat/chat.vue";
import { ElNotification } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import { useStore } from "vuex";
import SyntheseIntervenant from "@/views/still/fournisseur/syntheseIntervenant.vue";
import PlanningPresta from "@/views/still/fournisseur/planningPresta.vue";
import MapPresta from "@/views/still/fournisseur/mapPresta.vue";

// import { DatePicker } from 'v-calendar';

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    affTicket,
    chat,
    SyntheseIntervenant,
    // DatePicker,
    PlanningPresta,
    MapPresta
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {
    moment.default.locale("fr");
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      loaderEnabled: true,
      allContacts: [] as any,
      myList: [] as any,
      drawer: ref(false),
      drawerImport: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      search: "",
      ticketSelect: {} as any,
      valTis: 0,
      valTicket: {} as any,
      selTicket : 0,
      topChat : 95,
      hauteurChat: 124,
      loaderUpload : false,
      role_web : store.getters.currentUser.con_role_web,
      type_stat : 'tis_status',
      compteur : 0,
      onUpload : 0,
      affMap : false,
      rangeFilter: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 5)
      },
      colors:['33b1f9','fd9800','8fbf30','cd0067','6601ff','006766','0000cc','019934','fe0000','33b1f9','fd9800','8fbf30','cd0067','6601ff','006766','0000cc','019934','fe0000'],

    });
    const tableHeader = ref([
      {
        name: "Ticket",
        key: "tic_seq",
        sortable: true,
      },
      {
        name: "Planification",
        key: "tis_date_planif",
        sortable: true,
      },
      {
        name: "Statut",
        key: "tis_status",
        sortable: true,
      },
      {
        name: "Fichiers",
        key: "total_files",
        sortable: true,
      },
      {
        name: "Actions",
        key: "tic_knum_societe",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Statut",
        key: "tis_status",
        label: "tis_status",
      },
      {
        name: "Client",
        key: "soc_nom_societe",
        label: "soc_nom_societe",
      },
      {
        name: "Intervenant",
        key: "presta_nom_prenom",
        label: "presta_nom_prenom",
      },
      {
        name: "Planification",
        key: "presta_date_planif",
        label: "presta_date_planif",
      },
      {
        name: "Pièces jointes",
        key: "xfile",
        label: "xfile",
      },
    ]);

    const ExcelFormat = ref({
      "Ticket": "tic_seq",
      "Création": "tic_d_creation",  
      "Libellé du ticket": "tic_c_label",
      "Intervenant": "presta_nom_prenom",
      "Statut": "tis_status",
    });


    const handleClickPane = () => {
      
      if (activeName.value=='third') {
        setTimeout(() => {
          state.affMap = activeName.value=='third'
        }, 500)
      } else {
        state.affMap = false;
      }


      
    }

    const activeName = ref('first')

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_3_month = (d) => {
      const currentDate = moment.default().add(3, 'M');
      return moment.default(d) > currentDate;
    }

    const refreshRouter = () => {
      emit("refreshRouter" , 1);
    }

    const reloadRow = async (tisSeq) => {
      const getTicketsPresta = await getAxios("/getTicketsPresta/"+ tisSeq);
      Object.keys(state.myList.filter(element=> element.tis_seq == tisSeq)[0]).forEach(function(key) {
        state.myList.filter(element=> element.tis_seq ==tisSeq)[0][key] = getTicketsPresta.results[0][key];
      })
      state.myList.filter(element=> element.tis_seq == tisSeq)[0].old_tis_date_planif = state.myList.filter(element=> element.tis_seq == tisSeq)[0].tis_date_planif;
      state.myList.filter(element=> element.tis_seq == tisSeq)[0].old_tis_knum_contact_presta = state.myList.filter(element=> element.tis_seq == tisSeq)[0].tis_knum_contact_presta;
    }


    const applyChange = async (data) => {
      if(data.tis_date_planif) data.tis_date_planif = moment.default(data.tis_date_planif).format("YYYY-MM-DD");
      state.compteur = state.compteur +1;
      mAxiosApi
        .post("updateInterDate", data)
        .then(async () => {
          await reloadRow(data.tis_seq);
        })
        .catch((err) => {
          console.error(err);
        });
    };



    const affDetail = async (ticSeq, tisSeq = 0, xDisplay = true) => {
      await reloadRow(tisSeq);
      state.selTicket = ticSeq;
      state.valTis = tisSeq;
      let myList = await getAxios("/getTicket/" + ticSeq + "/" + tisSeq);
      myList.results.tic_staging.forEach((el) => {
        el.fichiers = JSON.parse(el.fichiers);
      })
      state.valTicket = myList.results;    
      if (xDisplay) state.drawer = true;
    };


    const changeStatus = async (data) => {

      if (data.tis_status == "Réalisé") {

        if (!data.tis_date_planif) { 
          ElMessageBox.alert('Impossible ! Aucune date d\'intervention !', 'Erreur', {
            confirmButtonText: 'OK',
            callback: () => {
              if (state.valTicket) { affDetail(state.valTicket.tic_seq, data.tis_seq, false); }else { reloadRow(data.tis_seq); }
            },
          })
          return false;
         }

        if (!data.tis_knum_contact_presta) { 
          ElMessageBox.alert('Impossible ! Aucun intervenant défini !', 'Erreur', {
            confirmButtonText: 'OK',
            callback: () => {
              if (state.valTicket) { affDetail(state.valTicket.tic_seq, data.tis_seq, false); }else { reloadRow(data.tis_seq); }
            },
          })
          return false;
         }

         

        ElMessageBox.confirm(
          'Une fois que le statut sera passé à "Réalisé", il vous sera impossible de modifier l\'intervenant et la date du ticket. Souhaitez-vous passer ce ticket en "Réalisé" ?', 'Confirmation',
          { confirmButtonText: 'Oui', cancelButtonText: 'Non, annuler', type: 'warning', }
        )
        .then(() => {
          applyStatus(data);
        })
        .catch(async () => {
          if (state.valTicket) { affDetail(state.valTicket.tic_seq, data.tis_seq, false); }else { await reloadRow(data.tis_seq); }
        })
      }else {
        applyStatus(data);
      }

    };

    const applyStatus = async (data) => {

        state.compteur = state.compteur +1;

        state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_status = data.tis_status;
        state.initialMyList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_status = data.tis_status;

        mAxiosApi
          .post("updateStatusNext", data)
          .then(async () => {
            await reloadRow(data.tis_seq);
          })
          .catch((err) => {
            console.error(err);
          });


    };


    const affTicket = (data) => {
      console.log('AAAAA');
      affDetail(data.tic_seq, data.tis_seq);
    }

    const styleImgStatus = (data) => {
      if (data.tic_c_status == "Traité") {
        return "media/icons/duotune/arrows/arr085.svg";
      } else {
        return "media/icons/duotune/general/gen012.svg";
        
      }
    };

    const styleBtnStatus = (data) => {
      if (data.tic_c_status == "Traité") {
        return "btn-icon-success bg-white";
      } else {
         return "btn-icon-dark bg-white svg-icon svg-icon-sm-6 ";
      }
    };

    const dateChangePlanif= (data) => {

      if (!data.tis_date_planif) data.tis_date_planif = null;
      
      state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_date_planif = data.tis_date_planif;
      state.initialMyList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_date_planif = data.tis_date_planif;

      applyChange(data);

    };

    const changeInter= (data) => {
      // console.log(state.myList.filter(element=> element.tis_seq == data.tis_seq));

      if (!data.tis_knum_contact_presta) data.tis_knum_contact_presta = null;

      state.myList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_knum_contact_presta = data.tis_knum_contact_presta;
      state.initialMyList.filter(element=> element.tis_seq == data.tis_seq)[0].tis_knum_contact_presta = data.tis_knum_contact_presta;

      applyChange(data);

    };

    const compressImage = async (file, { quality = 1, type = file.type }) => {
        // Get as image data
        const imageBitmap = await createImageBitmap(file);

        // Draw to canvas
        const canvas = document.createElement('canvas');
        
        const typeImg = imageBitmap.width > imageBitmap.height ? "W" : "H";
        const typeSize = typeImg == "W" ? imageBitmap.width : imageBitmap.height;
        
        let ratio = 1;
        
       
       if (typeSize> 1800) {
        if (typeImg == "W" ) {
            ratio = imageBitmap.width / imageBitmap.height;
            canvas.width = 1800;
            canvas.height = 1800 / ratio;
        } else {
            ratio = imageBitmap.height / imageBitmap.width;
            canvas.height = 1800;
            canvas.width = 1800 / ratio;
        }
       }else {
          canvas.width = imageBitmap.width;
          canvas.height = imageBitmap.height;
       }


        const ctx = canvas.getContext('2d');
        if (ctx) {
          //ctx.drawImage(imageBitmap, 0,0);
          ctx.drawImage(imageBitmap, 0,0, imageBitmap.width, imageBitmap.height,0 ,0 , canvas.width, canvas.height); 
        } 

        // Turn into Blob
        return await new Promise((resolve) =>
            canvas.toBlob(resolve, type, quality)
        );
    };


    const submitUpload = async () => {
      state.onUpload = 1;
      state.loaderUpload = true;
      const file1: any = document.querySelector('input[type=file]');

      const file = file1.files[0];

        if (file) {
            var reader = new FileReader();

            if (file.type.startsWith('image')) {
              const compressedFile: any = await compressImage(file, {
                  quality: 0.5,
                  type: 'image/jpeg',
              });
              reader.readAsDataURL(compressedFile);
            }else {
              reader.readAsDataURL(file);
            }
            reader.onload = async function(e) {
                if(e.target){
                    const form = {
                      tif_knum_ticket: state.selTicket,
                      tif_knum_ticket_services: state.valTis,
                      files : [{ name: file.name, data: e.target.result, size: file.size }], //e.target.result
                    }
                    //console.log(form);
                    mAxiosApi
                      .post("/addFileTicketService", form)
                      .then( async() => {
                        affDetail(state.selTicket, state.valTis);
                        state.onUpload = 0;
                        ElNotification({
                          title: 'Succès',
                          message: 'Fichier ajouté',
                          type: 'success',
                        })
                      })

                } 
            };
      }

    }

    const statusJson = (str) =>{
      return JSON.parse(str);
    } ;

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Suivi des prestations", []);

      let myContacts = await getAxios("/getContacts");
      state.allContacts = myContacts.results;

      let myList = await getAxios("/getTicketsPresta");

      myList.results.forEach(
        function(item: any){
          if (item.tis_date_planif){
            item.tis_date_planif = moment.default(item.tis_date_planif + ' 12:00 ');
          } 

          item.old_tis_date_planif = item.tis_date_planif;
          item.old_tis_knum_contact_presta = item.tis_knum_contact_presta;

        }
      );

      state.initialMyList = myList.results;
      state.myList = myList.results;      

      if (state.myList.length > 0){
        if (router.currentRoute.value.params.ticid) {
          affDetail(router.currentRoute.value.params.ticid);
        }
        if (router.currentRoute.value.params.status) {
          tableFilters.value.filter(el => el.key=="tic_c_status")[0]['default'] = [router.currentRoute.value.params.status];
        }
      }

      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      test_3_month,
      affDetail,
      tableFilters,
      ExcelFormat,
      refreshRouter,
      styleImgStatus,
      styleBtnStatus,
      dateChangePlanif,
      changeInter,
      applyChange,
      submitUpload,
      changeStatus,
      handleClickPane,
      activeName,
      affTicket,
      statusJson
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>

<style>
.el-dropdown-menu__item:hover {
  cursor: pointer;
  background-color: #e4e7ed !important;
}
</style>